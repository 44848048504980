import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { getContentsAsyncThunk } from "./contentSlice";


export const gethomePageProductInfoAsyncThunk = createAsyncThunk(
  "homePageProduct/homePageProductInfo",
  catchAsync(async ({data}, {dispatch, getState}) => {
    const state = getState();
    const response = await ApiRequests.getHomePageProductInfo({
      ...data, 
      // seller_id: '7495010442131638755',
      country_code: state.sellerInfo.sellerInfo.countryCode,
      scroll_param: `${state.homePageProduct.homePageProductInfo.scrollParam}`, 
      next_scroll_param: `${state.homePageProduct.homePageProductInfo.scrollParam}`,
      page_size: "20",
      query: ""
    });
    console.log("The get Response is:", response)
    return response?.data;
  })
);







///////////////////////////////////////////////////

const initialState = {
  //news states
  homePageProductInfo: {
    scrollParam: 0,
    results: null
  },

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  // search: null,
  // categoryId: null,
  // categories: [],
  // order: "asce",
};

const homePageProductSlice = createSlice({
  name: "homePageProduct",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(gethomePageProductInfoAsyncThunk.fulfilled, (state, action) => {
          if (action.payload) {
            state.homePageProductInfo.results= action.payload.data
          } else {
            state.homePageProductInfo.results = null
          }
      })
      
     
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            gethomePageProductInfoAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  homePageProductSlice.actions;

export default homePageProductSlice.reducer;
