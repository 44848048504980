import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  homePageProductSelector,
  sellerInfoSelector,
} from "../store/selectors";
import { gethomePageProductInfoAsyncThunk } from "../store/homePageProduct/homePageProductSlice";
import "../homepageProduct.css";

const HomepageProduct = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const sellerInfo = useSelector(sellerInfoSelector);
  const homePageProductsResults = useSelector(homePageProductSelector);
  console.log(
    "🚀 ~ HomepageProduct ~ homePageProductsResults:",
    homePageProductsResults
  );

  const stream_data = homePageProductsResults?.store_common_data?.live_info
    ?.stream_data?.live_core_sdk_data?.pull_data?.stream_data
    ? JSON.parse(
        homePageProductsResults?.store_common_data?.live_info?.stream_data
          ?.live_core_sdk_data?.pull_data?.stream_data
      )
    : "";

  useEffect(() => {
    if (sellerInfo.sellerId && !homePageProductsResults) {
      dispatch(
        gethomePageProductInfoAsyncThunk({
          data: {
            seller_id: sellerInfo.sellerId,
          },
        })
      ).then(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (sellerInfo.sellerId && !homePageProductsResults) {
      dispatch(
        gethomePageProductInfoAsyncThunk({
          data: {
            seller_id: sellerInfo.sellerId,
          },
        })
      );
    }
  }, [sellerInfo]);

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <h1 className="text-2xl font-medium text-white">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="homepage-product h-full">
      {/* <div className="flex items-center justify-center h-full">
        <h1 className="text-2xl font-medium text-white">
          This section is under construction. We are working on it.
        </h1>
      </div> */}
      {homePageProductsResults && (
        <div className="homepage-content">
          <div
            className="store-header"
            style={{
              backgroundImage: `url(${homePageProductsResults?.common_store_header_data?.background?.image?.url_list[0]})`,
            }}
          >
            <div className="store-info">
              <img
                src={
                  homePageProductsResults?.store_common_data?.shop?.logo
                    .url_list[0]
                }
                alt="Shop Logo"
              />
              <h1>
                {homePageProductsResults?.store_common_data?.shop.shop_name}
              </h1>
              <p>
                Rating:{" "}
                {homePageProductsResults?.store_common_data?.shop.shop_rating}
              </p>
              <p>
                Reviews:{" "}
                {homePageProductsResults?.store_common_data?.shop.review_count}
              </p>
              <p>
                Sold:{" "}
                {homePageProductsResults?.store_common_data?.shop.sold_count}
              </p>
            </div>
          </div>
          <div className="tabs">
            {homePageProductsResults?.tab_list_data?.tab_list.map((tab) => (
              <div key={tab.decoration_page_id} className="tab">
                {tab.tab_name}
              </div>
            ))}
          </div>
          <div className="products">
            <h2>Products</h2>
            {/* Add logic to display product data here */}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomepageProduct;
