import { combineReducers } from "@reduxjs/toolkit";
import creatorInfo from "./creator/creatorSlice";
import homePageProduct from "./homePageProduct/homePageProductSlice";
import recommendation from "./recommendation/recommendationSlice";
import productList from "./productList/productListSlice";
import recommendProduct from "./recommendProduct/recommendProductSlice";
import homeTabInfo from "./homeTabInfo/homeTabInfoSlice";
import homeTabList from "./homeTabList/homeTabListSlice";
import categoryInfo from "./categoryInfo/categoryInfoSlice";
import voucherList from "./voucherList/voucherListSlice";
import reviews from "./reviews/reviewsSlice";
import shopInfo from "./shopInfo/shopInfoSlice";
import sellerInfo from "./sellerInfo/sellerInfoSlice";

export const rootReducer = combineReducers({
  sellerInfo,
  creatorInfo,
  homePageProduct,
  recommendation,
  productList,
  recommendProduct,
  homeTabInfo,
  homeTabList,
  categoryInfo,
  voucherList,
  reviews,
  shopInfo
})