import { useDispatch, useSelector } from "react-redux";
import {
  reviewsCurrentPageSelector,
  reviewsHasMoreSelector,
  reviewsIsLoadingSelector,
  reviewsPreviousPagesSelector,
  reviewsSelector,
  sellerInfoSelector,
} from "../store/selectors";
import { useEffect, useRef, useState } from "react";
import {
  getNextReviewsAsyncThunk,
  getPreviousReviewsAsyncThunk,
  getReviewsAsyncThunk,
} from "../store/reviews/reviewsSlice";
import { toast } from "react-toastify";
import { Opacity } from "@mui/icons-material";

const Reviews = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const sellerInfo = useSelector(sellerInfoSelector);
  const reviewsData = useSelector(reviewsSelector);
  const hasMore = useSelector(reviewsHasMoreSelector);
  const currentPage = useSelector(reviewsCurrentPageSelector);
  const previousPages = useSelector(reviewsPreviousPagesSelector);
  // const loading = useSelector(reviewsIsLoadingSelector);
  console.log("Previous Pages are", previousPages);

  const scrollRef = useRef(null);

  const styles = {
    opacity: loading ? 0.5 : 1,
  };

  console.log("reviews: ", reviewsData);

  useEffect(() => {
    const getReviews = async () => {
      try {
        await dispatch(
          getReviewsAsyncThunk({
            data: {
              seller_id: sellerInfo.sellerId,
            },
          })
        ).then(() => setLoading(false));
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      } catch (error) {
        console.log(error);
        toast.error("Couldn't complete GET request.");
      }
    };

    getReviews();
  }, []);

  useEffect(() => {
    const getReviews = async () => {
      try {
        await dispatch(
          getReviewsAsyncThunk({
            data: {
              seller_id: sellerInfo.sellerId,
            },
          })
        );
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      } catch (error) {
        console.log(error);
        toast.error("Couldn't complete GET request.");
      }
    };

    getReviews();
  }, [sellerInfo]);

  const goToNextPage = async () => {
    if (loading) return;
    try {
      if (hasMore) {
        await dispatch(
          getNextReviewsAsyncThunk({
            data: {
              seller_id: sellerInfo.sellerId,
            },
          })
        );
      }
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.log(error);
      toast.error("Couldn't Load the Previous Page");
    }
  };

  const gotoPreviousPage = async () => {
    if (loading) return;
    try {
      if (previousPages.length > 1) {
        await dispatch(
          getPreviousReviewsAsyncThunk({
            data: {
              seller_id: sellerInfo.sellerId,
            },
          })
        );
      }
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.log(error);
      toast.error("Couldn't Load the Previous Page");
    }
  };

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <h1 className="text-2xl font-medium text-white">Loading...</h1>
      </div>
    );
  }
  return (
    <div className="reviews-page-container" ref={scrollRef} style={styles}>
      {
        <>
          {reviewsData && (
            <>
              {reviewsData?.review_items?.length > 0 ? (
                <div className="reviews-container">
                  <h1 className="text-[25px] text-white font-semibold pb-5">
                    {reviewsData?.top_text.length > 0
                      ? reviewsData?.top_text
                      : "Pick authentic and helpful reviews for you"}
                  </h1>
                  <div className="grid grid-cols-1 gap-5">
                    {reviewsData?.review_items.map(
                      (review, index) => (
                        <div
                          className="bg-[#ffffff14] text-white rounded-md p-4 w-full hover:bg-[#ffffff28] transition"
                          key={index}
                        >
                          <div className="flex flex-col justify-between gap-4">
                            <div className="flex gap-3">
                              {/* Image */}
                              <div className="w-[80px] h-[80px] flex items-center overflow-hidden rounded-full">
                                <img
                                  src={
                                    review.product_card.display_image
                                      ?.url_list[0]
                                  }
                                  alt=""
                                  className="w-[80px] h-[80px] rounded-[2px] object-cover"
                                />
                              </div>

                              <div className="flex flex-col h-[80px] justify-between">
                                {/* Username */}
                                <div className="text-[25px] font-bold">
                                  {review?.review_item?.review_user?.name}
                                </div>

                                {/* Product Name */}
                                <div className="flex gap-3 items-center justify-center">
                                  <p className="text-sm opacity-90 text-center font-medium pb-2">
                                    {review?.product_card?.display_product_name}
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* Reviews */}
                            <div className="flex items-center gap-1">
                              <p>
                                Rating: ({review?.review_item?.review?.rating})
                              </p>
                              <div className="flex">
                                {Array.from({
                                  length: review?.review_item?.review?.rating,
                                }).map((_, index) => (
                                  <img
                                    key={index}
                                    src="Images/star.png"
                                    alt={`yellow-star ${index}`}
                                    className="w-[16px] h-[16px] rounded-[2px] object-cover"
                                  />
                                ))}
                                {Array.from({
                                  length:
                                    5 - review?.review_item?.review?.rating,
                                }).map((_, index) => (
                                  <img
                                    key={index}
                                    src="Images/gray-star.png"
                                    alt={`gray-star ${index}`}
                                    className="w-[16px] h-[16px] rounded-[2px] object-cover"
                                  />
                                ))}

                                {/* <img src="Images/star.png" alt="" className="w-[16px] h-[16px] rounded-[2px] object-cover" />

                              <img src="Images/star.png" alt="" className="w-[16px] h-[16px] rounded-[2px] object-cover" />

                              <img src="Images/star.png" alt="" className="w-[16px] h-[16px] rounded-[2px] object-cover" />

                              <img src="Images/star.png" alt="" className="w-[16px] h-[16px] rounded-[2px] object-cover" /> */}
                              </div>
                            </div>

                            {/* Review content */}
                            <div className="text-base opacity-80">
                              <p>{review?.review_item?.review?.display_text}</p>
                            </div>

                            {/* Reviews Image */}
                            <div className="flex items-center justify-between gap-3">
                              {review?.review_item?.review?.images.map(
                                (image, index) => (
                                  <div
                                    className="w-[100%] h-[150px] flex items-center overflow-hidden rounded-sm"
                                    key={index}
                                  >
                                    <img
                                      src={image.url_list[0]}
                                      alt=""
                                      className="w-[100%] h-[150px] object-cover rounded-sm"
                                    />
                                  </div>
                                )
                              )}
                            </div>

                            {/* Date */}
                            <div className="text-sm">
                              <p>
                                {
                                  review?.review_item?.review
                                    ?.review_timestamp_fmt
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      )

                      // <div className="review">
                      //   <span>
                      //   <img src={review.product_card.display_image?.url_list[0]} alt="" />
                      //   <h1>Product: {review?.product_card?.display_product_name}</h1>
                      //   <h1>Rating: {review?.review_item?.review?.rating}</h1>
                      //   <h1>Dated: {review?.review_item?.review?.review_timestamp_fmt}</h1>
                      //   </span>

                      //   <h1>User: {review?.review_item?.review_user?.name}</h1>
                      //   <p>Review: {review?.review_item?.review?.display_text}</p>
                      //   {
                      //     review?.review_item?.review?.images &&
                      //     <span className="images-list">
                      //       Images:
                      //       {
                      //         review?.review_item?.review?.images.map((image, index) =>
                      //           <img src={image.url_list[0]} alt="" key={index}/>
                      //         )
                      //       }
                      //     </span>
                      //   }
                      // </div>
                    )}
                  </div>
                  <div className="flex items-center justify-end gap-3 mt-5">
                    {previousPages.length > 1 && (
                      <span
                        className={`next-page-indicator bg-[#AF47D2] w-[40px] py-2 text-center text-white text-lg font-semibold hover:cursor-pointer ${
                          loading
                            ? "hover:cursor-not-allowed"
                            : "hover:cursor-pointer"
                        }`}
                        onClick={() => gotoPreviousPage()}
                      >{`<`}</span>
                    )}
                    <span
                      className={
                        "current-page bg-[#AF47D2] w-[40px] py-2 text-center text-white text-lg font-semibold"
                      }
                    >
                      {currentPage}
                    </span>
                    {hasMore && (
                      <span
                        className={`next-page-indicator bg-[#AF47D2] w-[40px] py-2 text-center text-white text-lg font-semibold hover:cursor-pointer ${
                          loading
                            ? "hover:cursor-not-allowed"
                            : "hover:cursor-pointer"
                        }`}
                        onClick={() => goToNextPage()}
                      >{`>`}</span>
                    )}
                  </div>
                </div>
              ) : (
                <h1>No Reviews available for this seller.</h1>
              )}
            </>
          )}
        </>
      }
    </div>
  );
};

export default Reviews;
