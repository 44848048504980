import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import TiktokCircleImage from "../assest/tiktokcircle.png";
import Playlistcards from "../Components/Cards/Playlistcards";
import { useDispatch, useSelector } from "react-redux";
import { getSellerInfoAsyncThunk, nullifySellerInfo, setCountryCode } from "../store/sellerInfo/sellerInfoSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { countryCodes } from "../constants";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sellerInfo = useSelector(state => state.sellerInfo.sellerInfo)

  const searchBarRef = useRef(null)
  

  useEffect(() => {

    if(sellerInfo.sellerId === null) navigate('/')
    
  }, [])

  const handleSearchClick = () => {
    // if (searchBarRef.current.value === sellerInfo.uniqueId) {
    //   toast.error("Please enter a different username.")
    //   return;
    // } else 

    searchBarRef.current.value = searchBarRef.current.value.toLowerCase()

    if (!searchBarRef.current.value.length > 0) {
      toast.error("Please enter a username inside the search field.")
      return
    }
    if(sellerInfo.countryCode === null){
      toast.error("Please select a country.");
      return;
    }

    dispatch(nullifySellerInfo())

    try {
      dispatch(getSellerInfoAsyncThunk({
        data: {
          uniqueId: searchBarRef.current.value
        }
      }))
      navigate('/creator-info')
    } catch (error) {
      console.log(error)
    }


  };

  const setCountry = (country) => {
    dispatch(
      setCountryCode(countryCodes[country])
    );
  }

  return (
    <div className="mt-[6%]">
      <div className="hero-section max-w-[500px] w-11/12 m-auto">
        <div className="top-icons">
          <div
            style={{
              width: "54px",
              height: "54px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="icons"
          >
            <img
              className="transition-all duration-300"
              src={TiktokCircleImage}
              style={{
                width: "51px",
                height: "51px",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "50%",
              }}
              alt="tiktok-logo"
            />
          </div>
        </div>
        <div className="">
          <div className="relative text-white mb-7 max-w-[450px] mx-auto">
            {/* <p className="absolute top-[10px] left-5">@</p> */}
            <div className="flex items-center rounded-[100px] border bg-gray-900 border-gray-700 focus:border-[#AF47D2]">
              <div className="relative inline-block">
                <div className="group">
                  <button
                    type="button"
                    className="inline-flex justify-center items-center rounded-l-[100px] pl-4 pr-2 py-2 text-sm font-medium text-white focus:outline-none"
                  >
                    {
                      Object.keys(countryCodes).filter(country => countryCodes[country] === sellerInfo.countryCode)[0] ?? "Country"
                    }
                    {/* Dropdown arrow */}
                    <svg
                      className="w-4 h-4 ml-2 -mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path fillRule="evenodd" d="M10 12l-5-5h10l-5 5z" />
                    </svg>
                  </button>
                  {/* Dropdown menu */}
                  <div className="absolute top-[30px] right-0 text-left z-50 w-40 mt-1 origin-top-left bg-gray-800 border border-gray-500 divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-300">
                    <div className="py-1">
                      {
                        Object.keys(countryCodes).map((country, index) =>
                          <div
                            key={index}
                            className={`block px-4 py-2 text-sm text-gray-100 hover:bg-gray-600 hover:cursor-pointer ${countryCodes[country] === sellerInfo.countryCode ? 'bg-gray-600' : ""}`}
                            onClick={() => setCountry(country)}
                          >
                            {country}
                          </div>
                        )
                      }

                    </div>
                  </div>
                </div>
              </div>

              <input
                ref={searchBarRef}
                type="text"
                placeholder={" Add username or Link"}
                defaultValue={sellerInfo?.uniqueId ?? ''}
                data-e2e="common-StringInput-TUXTextInput"
                className="w-100 px-2 py-[10px] rounded-r-[100px] bg-gray-800 transition ease-in-out  w-full focus:outline-none"
                required
              />
            </div>
          </div>
          <div className="search-btn">
            <button
              onClick={handleSearchClick}
              className="border-[#AF47D2] border-2 text-[#AF47D2] bg-transparent hover:bg-[#AF47D2] hover:text-[#fff]  w-full flex items-center justify-center gap-2 font-medium py-3"
            >
              Search <span><FaSearch /></span>
            </button>
          </div>
        </div>
      </div>
      {sellerInfo.uniqueId && <Playlistcards />}
    </div>
  );
};

export default Home;
