import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { getContentsAsyncThunk } from "./contentSlice";


export const getVoucherListAsyncThunk = createAsyncThunk(
  "voucherList/getVoucherListAsyncThunk",
  catchAsync(async ({data}, {getState}) => {
    const state = getState();
    const response = await ApiRequests.getVoucherList({
      ...data,
      country_code: state.sellerInfo.sellerInfo.countryCode
      // seller_id: "7494792669357706170",
      // country_code: "TH"
    });
    console.log("The get Response is:", response?.data)
    return response?.data;
  })
);







///////////////////////////////////////////////////

const initialState = {
  //news states
  vouchersInfo: null,

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  // search: null,
  // categoryId: null,
  // categories: [],
  // order: "asce",
};

const voucherListSlice = createSlice({
  name: "voucherList",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(getVoucherListAsyncThunk.fulfilled, (state, action) => {
          if (action.payload) {
            state.vouchersInfo = action.payload.data
          } else {
            state.vouchersInfo = null
          }
      })
      
     
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getVoucherListAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  voucherListSlice.actions;

export default voucherListSlice.reducer;
