import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sellerInfoSelector, voucherInfoSelector } from "../store/selectors";
import { getVoucherListAsyncThunk } from "../store/voucherList/voucherListSlice";

const VoucherInfo = () => {
  const dispatch = useDispatch();

  const sellerInfo = useSelector(sellerInfoSelector);
  const vouchersInfo = useSelector(voucherInfoSelector);
  console.log("🚀 ~ VoucherInfo ~ vouchersInfo:", vouchersInfo);
  console.log(
    "Vouchers Info: ",
    vouchersInfo?.voucher_list
      ? JSON.parse(vouchersInfo.voucher_list[0].da_info)
      : ""
  );
  console.log("Vouchers Info: ", vouchersInfo);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(
      getVoucherListAsyncThunk({
        data: {
          seller_id: sellerInfo.sellerId,
        },
      })
    ).then(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <h1 className="text-2xl font-medium text-white">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="voucher-info-container text-white h-full">
      {vouchersInfo && (
        <>
          {vouchersInfo?.tc_data?.sellercoupon_tc &&
          vouchersInfo.voucher_list.length !== 0 ? (
            <div className="vouchers-info">
              <div className="max-h-[300px] overflow-y-auto">
                <h1 className="text-2xl text-white font-semibold pb-8">
                  Terms and Conditions
                </h1>
                <p className="text-sm text-white opacity-80">
                  {vouchersInfo?.tc_data?.sellercoupon_tc}
                </p>
              </div>

              <div className="grid grid-cols-2 gap-2">
                {vouchersInfo.voucher_list.map((voucher) => (
                  <div className="text-white w-full flex transition py-7">
                    <div className=" w-full border border-slate-700 shadow-xl shadow-slate-700  relative">
                      <div>
                        <img
                          src={voucher?.background?.url_list[0]}
                          alt="logo"
                          className="absolute w-full"
                        />
                      </div>
                      <div className="gap-8 py-7 px-3">
                        <div className="flex items-center flex-col gap-5 justify-between text-start w-full">
                          <h1 className="text-2xl text-white font-semibold">
                            Vouchers Found: {vouchersInfo.voucher_list.length}
                          </h1>

                          <button
                            disabled={
                              !voucher.voucher_restriction.unuse_amount > 0
                            }
                            style={{
                              background:
                                voucher.voucher_restriction.unuse_amount > 0
                                  ? "#AF47D2"
                                  : "red",
                            }}
                            className="w-full py-2 font-medium text-base"
                          >
                            {voucher.button_text}
                          </button>

                          {/* Points */}
                          <div className="flex items-center gap-5 flex-col w-full justify-between">
                            <div className="flex justify-between items-center w-full">
                              <p className="opacity-80">Shop Name:</p>
                              <p className="text-[16px] font-bold opacity-100 text-start">
                                {voucher.shop_name_text}
                              </p>
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <p className="opacity-80">Discount:</p>
                              <p className="text-[16px] font-bold opacity-100 text-start">
                                {voucher.discount_text}
                              </p>
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <p className="opacity-80">Condition:</p>
                              <p className="text-[16px] font-bold opacity-100 text-start">
                                {voucher.threshold_text}
                              </p>
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <p className="opacity-80">Usage:</p>
                              <p className="text-[16px] font-bold opacity-100 text-start">
                                {voucher.use_limit_text}
                              </p>
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <p className="opacity-80">Left:</p>
                              <p className="text-[16px] font-bold opacity-100 text-start">
                                {voucher.voucher_restriction.restriction_text}
                              </p>
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <p className="opacity-80">Validity:</p>
                              <p className="text-[16px] font-bold opacity-100 text-start">
                                {voucher.valid_time_text}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <h1 className="text-2xl font-medium text-white">
                No Vouchers Available for Your Region from this Seller.
              </h1>
            </div>
          )}
        </>
      )}
      {/* <div className='voucher'> */}
      {/* <h1>Voucher by:<===<<Bhai isay baad mein erase kar dena {voucher.cost_role_text}</h1> */}
      {/* <h1>{voucher.shop_name_text}</h1> */}
      {/*                           
                          <h1>Discount: {voucher.discount_text}</h1>
                          
                          <span><img src={voucher?.background?.url_list[0]} alt="logo" /> <h1>{voucher.cost_role_text}</h1></span>
                          <h1>Condition:{voucher.threshold_text}</h1>
                          <h1>Usage: {voucher.use_limit_text}</h1>
                          <h1>Left: {voucher.voucher_restriction.restriction_text}</h1>
                          <h1>Validity: {voucher.valid_time_text}</h1>
                          <button disabled={!voucher.voucher_restriction.unuse_amount > 0} style={{border: voucher.voucher_restriction.unuse_amount > 0 ? "1px solid whitesmoke" : "1px solid red"}}>{voucher.button_text}</button>
                          </div> */}
    </div>
  );
};

export default VoucherInfo;
