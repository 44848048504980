import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { getContentsAsyncThunk } from "./contentSlice";


export const getSellerInfoAsyncThunk = createAsyncThunk(
  "sellerInfo/getSellerInfoAsyncThunk",
  catchAsync(async ({data}) => {
    const response = await ApiRequests.getSellerInfo(data);
    console.log("The get Response is:", response?.data)
    return response?.data;
  })
);







///////////////////////////////////////////////////

const initialState = {
  //news states
  sellerInfo: {
    uniqueId: null,
    id: null,
    sellerId: null,
    sellerReviews: null,
    countryCode: null,
  },

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  // search: null,
  // categoryId: null,
  // categories: [],
  // order: "asce",
};

const sellerInfoSlice = createSlice({
  name: "sellerInfo",
  initialState,
  reducers: {
    setSellerId(state, action) {
      state.sellerInfo.sellerId = action.payload
    },

    setSellerReviews(state, action) {
      state.sellerInfo.sellerReviews = action.payload
    },

    setCountryCode(state, action) {
      state.sellerInfo.countryCode = action.payload
    },


    nullifySellerInfo(state, action) {
      state.sellerInfo.id = null
      state.sellerInfo.sellerId = null
      state.sellerInfo.uniqueId = null
      state.sellerInfo.sellerReviews = null
      state.sellerInfo.errors = {}
      state.sellerInfo.loadings = {}
      state.sellerInfo.errorMessages = {}
      state.sellerInfo.errorCodes = {}
      state.sellerInfo.paramsForThunk = {}
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(getSellerInfoAsyncThunk.fulfilled, (state, action) => {
          if (action.payload) {
            state.sellerInfo.id = action.payload.data.userInfo.user.id
            state.sellerInfo.uniqueId = action.payload.data.userInfo.user.uniqueId
          } else {
            state.sellerInfo = null
          }
      })
      
     
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getSellerInfoAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setSellerId, nullifySellerInfo, setSellerReviews, setCountryCode } =
  sellerInfoSlice.actions;

export default sellerInfoSlice.reducer;
