import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCreatorInfoAsyncThunk } from "../store/creator/creatorSlice";
import { creatorInfoSelector, sellerInfoSelector } from "../store/selectors";

const CreatorInfo = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const sellerInfo = useSelector(sellerInfoSelector);
  const creatorInfo = useSelector(creatorInfoSelector);
  console.log(creatorInfo);

  useEffect(() => {
    if (sellerInfo && !creatorInfo) {
      dispatch(getCreatorInfoAsyncThunk(sellerInfo.id)).then(() =>
        setLoading(false)
      );
    }
  }, []);

  useEffect(() => {
    dispatch(getCreatorInfoAsyncThunk(sellerInfo.id));
  }, [sellerInfo]);

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <h1 className="text-2xl font-medium text-white">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="creator-info-container h-full">
      {creatorInfo && (
        <>
          {creatorInfo?.shop ? (
            <>
              <div className="text-white border border-slate-700 shadow-xl shadow-slate-700 w-[320px] m-auto flex transition py-4">
                <div className="flex items-start flex-col gap-8 m-auto">
                  {/* Image */}
                  <div className="m-auto w-[200px] h-[200px] rounded-full flex items-center justify-center	overflow-hidden relative">
                    <img
                      src={creatorInfo.shop.logo.url_list[0]}
                      alt="creator-logo"
                      height={creatorInfo.shop.logo.height}
                      className="w-[200px] h-[200px] rounded-full object-cover "
                    />
                  </div>

                  <div className="flex items-start flex-col gap-5 justify-between text-start w-[300px] m-auto">
                    <div className="flex justify-between items-center w-full">
                      <p className="opacity-80">Name:</p>
                      <p className="text-[16px] font-bold opacity-100 text-start">
                        {creatorInfo?.shop?.shop_name}
                      </p>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <p className="opacity-80">Rating:</p>
                      <p className="text-[16px] font-bold opacity-100 text-start flex items-center gap-1">
                        {creatorInfo?.shop?.shop_rating}
                        <img
                          src={
                            Number(creatorInfo?.shop?.shop_rating) > 3
                              ? "Images/star.png"
                              : "Images/gray-star.png"
                          }
                          alt={`yellow-star`}
                          className="w-[14px] h-[14px] rounded-[2px] object-cover"
                        />
                      </p>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <p className="opacity-80">display review count:</p>
                      <p className="text-[16px] font-bold opacity-100 text-start">
                        {creatorInfo?.shop?.display_review_count}
                      </p>
                    </div>

                    <div className="flex justify-between items-center w-full">
                      <p className="opacity-80">Sold:</p>
                      <p className="text-[16px] font-bold opacity-100 text-start">
                        {creatorInfo?.shop?.display_sold_count}
                      </p>
                    </div>

                    <div className="flex justify-between items-center w-full">
                      <p className="opacity-80">Reviews:</p>
                      <p className="text-[16px] font-bold opacity-100 text-start">
                        {creatorInfo?.shop?.display_review_count}
                      </p>
                    </div>
                    {/* New Data */}
                    <div className="flex justify-between items-center w-full">
                      <p className="opacity-80">Status:</p>
                      <p className="text-[16px] font-bold opacity-100 text-start">
                        {creatorInfo?.shop?.is_on_holiday ? "Closed" : "Open"}
                      </p>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <p className="opacity-80">Positive Experience:</p>
                      <p className="text-[16px] font-bold opacity-100 text-start">
                        {creatorInfo?.shop?.exp_rate_percentile + "%"}
                      </p>
                    </div>

                    <div className="flex justify-between items-center w-full">
                      <p className="opacity-80">seller_id:</p>
                      <p className="text-[16px] font-bold opacity-100 text-start">
                        {creatorInfo?.shop?.seller_id}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <img src={creatorInfo.shop.logo.url_list[0]} alt="creator-logo" height={creatorInfo.shop.logo.height} />
              <p>Store Name: {creatorInfo?.shop?.shop_name}</p> 
                 <p className='text-white'>Shop Rating: {creatorInfo?.shop?.shop_rating}</p>
                <p className='text-white'>Review Count: {creatorInfo.shop.review_count}</p>
                <p className='text-white'>Sold Count: {creatorInfo.shop.sold_count}</p>
                <p className='text-white'>Display Review Count: {creatorInfo?.shop.display_review_count}</p>
                <p className='text-white'>Display Sold Count: {creatorInfo?.shop.display_sold_count} </p>*/}
            </>
          ) : (
            <div className="flex items-center justify-center h-full">
              <h1 className="text-2xl font-medium text-white">
                No Shop Found for this username
              </h1>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CreatorInfo;
