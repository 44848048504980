import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sellerInfoSelector, shopInfoSelector } from "../store/selectors";
import { getShopInfoAsyncThunk } from "../store/shopInfo/shopInfoSlice";
import { useNavigate } from "react-router-dom";

const ShopInfo = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sellerInfo = useSelector(sellerInfoSelector);
  const shopInfo = useSelector(shopInfoSelector);
  // const ratingExplaination = shopInfo?.shop?.rating_explanation.split(/(?=\d\.\s|(?=\n))/)
  console.log("ShopInfo is:", shopInfo);

  useEffect(() => {
    // if(!shopInfo && sellerInfo){
    dispatch(
      getShopInfoAsyncThunk({
        data: {
          shop_id: sellerInfo.sellerId,
        },
      })
    ).then(() => setLoading(false));
    // }
  }, []);

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <h1 className="text-2xl font-medium text-white">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="shop-info h-full">
      {shopInfo && (
        <>
          {shopInfo?.business_info ? (
            <>
              <div className="title-content pb-12">
                <h1 className="text-4xl text-white font-semibold pb-3">
                  {shopInfo?.badge_description?.title}
                </h1>
                <p className="text-white font-medium text-[15px]">
                  {shopInfo?.badge_description?.content}
                </p>
              </div>

              <div className="flex gap-5">
                <div className="text-white border border-slate-700 shadow-xl shadow-slate-700 m-auto flex transition py-4">
                  <div className="flex items-start flex-col gap-8 m-auto">
                    {/* Image */}
                    <div className="m-auto w-[200px] h-[200px] rounded-full flex items-center justify-center	overflow-hidden relative">
                      <img
                        src={shopInfo.shop.logo.url_list[0]}
                        alt="creator-logo"
                        height={shopInfo.shop.logo.height}
                        className="w-[200px] h-[200px] rounded-full object-cover "
                      />
                    </div>

                    <div className="flex items-start flex-col gap-5 justify-between text-start w-[320px] m-auto px-2">
                      <div className="flex justify-between items-center w-full">
                        <p className="opacity-80">Name:</p>
                        <p className="text-[16px] font-bold opacity-100 text-start">
                          {shopInfo?.shop?.shop_name}
                        </p>
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p className="opacity-80">Rating:</p>
                        <p className="text-[16px] font-bold opacity-100 text-start flex items-center gap-1">
                          {shopInfo?.shop?.shop_rating}
                          <img
                            src={
                              Number(shopInfo?.shop?.shop_rating) > 3
                                ? "Images/star.png"
                                : "Images/gray-star.png"
                            }
                            alt={`yellow-star`}
                            className="w-[14px] h-[14px] rounded-[2px] object-cover"
                          />
                        </p>
                      </div>
                      {/*  */}
                      <div className="flex justify-between items-center w-full">
                        <p className="opacity-80">Product-in-Queue Count:</p>
                        <p className="text-[16px] font-bold opacity-100 text-start">
                          {shopInfo?.shop?.on_sell_product_count}
                        </p>
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p className="opacity-80">Sold:</p>
                        <p className="text-[16px] font-bold opacity-100 text-start">
                          {shopInfo?.shop.display_sold_count}
                        </p>
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p className="opacity-80">Reviews:</p>
                        <p className="text-[16px] font-bold opacity-100 text-start">
                          {sellerInfo?.sellerReviews}
                        </p>
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p className="opacity-80">Positive Experience:</p>
                        <p className="text-[16px] font-bold opacity-100 text-start">
                          {shopInfo?.shop?.exp_rate_percentile + "%"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p className="opacity-80">Landline:</p>
                        <p className="text-[16px] font-bold opacity-100 text-start">
                          {shopInfo?.business_info?.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src={shopInfo.shop.logo.url_list[0]} alt="creator-logo" height={shopInfo.shop.logo.height} />
              <p>Store Name: {shopInfo?.shop?.shop_name}</p>
              <p>Store Landline: {shopInfo?.business_info?.phone}</p>
              <p>Shop Rating: {shopInfo?.shop?.shop_rating}</p>
              <p>Review Count: {shopInfo.shop.review_count}</p>
              <p>Sold Count: {shopInfo.shop.sold_count}</p> 
               <p>Display Review Count: {shopInfo?.shop.display_review_count}</p>
              <p>Display Sold Count: {shopInfo?.shop.display_sold_count}</p> */}

                <div>
                  <div className="Rating Explaination">
                    <h1 className="text-2xl text-white font-semibold">
                      Rating Explaination
                    </h1>
                    <p className="text-[14px] text-white font-medium opacity-90 py-3">
                      Store rating offers shopping experience insights based on
                      customer product reviews, after-sales service, and
                      shipping performance. It is calculated based on the
                      performance of stores that have more than 30 orders for
                      the last 90 days and updates daily.
                    </p>
                    <p className="text-xl text-white font-medium py-1">
                      Score breakdown:
                    </p>
                    <p className="text-sm text-white font-medium py-1">
                      The store rating is calculated using the following 3
                      metrics:
                    </p>
                    <p className="text-sm text-white font-medium py-1">
                      1. Product satisfaction: Including product reviews, and
                      refunds and returns due to quality issues
                    </p>
                    <p className="text-sm text-white font-medium py-1">
                      2. Shipping: Including order cancellations and dispatch
                      records
                    </p>
                    <p className="text-sm text-white font-medium py-1">
                      3. Customer service: Including seller responses to
                      customers and service complaint records.
                    </p>
                    <p className="text-sm text-white font-medium py-1">
                      The store rating is provided for reference only and
                      doesn't represent an endorsement by TikTok Shop of any
                      sellers or products. Reach out to{" "}
                      <a
                        href={shopInfo?.shop?.rating_explanation_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        TikTok Shop
                      </a>{" "}
                      if you have any questions.
                    </p>

                    {/* {ratingExplaination.map((paraLine, index) => {
                  console.log("rating explaination is: ", ratingExplaination) 
                  if(index === ratingExplaination.length - 1) return (<a href={shopInfo?.shop?.rating_explanation_link} target='blank'><p>{paraLine}</p></a>)
                  return (<p>{paraLine}</p>)
                }
                )} */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center h-full">
              <h1 className="text-2xl font-medium text-white">
                No Shop Found for this username
              </h1>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ShopInfo;
