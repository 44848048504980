import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { getContentsAsyncThunk } from "./contentSlice";


export const getProductListAsyncThunk = createAsyncThunk(
  "productList/getProductListAsyncThunk",
  catchAsync(async ({ data }, { getState }) => {
    const state = getState();
    const response = await ApiRequests.getProductList({
      ...data,
      country_code: state.sellerInfo.sellerInfo.countryCode
    });
    console.log("🚀 ~ catchAsync ~ response getProductListAsyncThunk:", response)
    return response?.data;
  })
);







///////////////////////////////////////////////////

const initialState = {
  //news states
  productList: null,

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  // search: null,
  // categoryId: null,
  // categories: [],
  // order: "asce",
};

const productListSlice = createSlice({
  name: "productList",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder

      .addCase(getProductListAsyncThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.productList = action.payload.data
        } else {
          state.productList = null
        }
      })


      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getProductListAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  productListSlice.actions;

export default productListSlice.reducer;
