import { createSelector } from "reselect"

//Selectors


export const sellerInfoSelector = (state) => state.sellerInfo.sellerInfo
export const productListSelector = (state) => state.productList.productList
export const HomeTabInfoSelector = (state) => state.homeTabInfo.homeTabInfo

export const creatorInfoSelector = (state) => state.creatorInfo.creatorInfo

export const shopInfoSelector = (state) => state.shopInfo.shopInfo

export const homePageProductSelector = (state) => state.homePageProduct.homePageProductInfo.results

export const recommendationsSelector = (state) => state.recommendation.recommendations
export const recommendationProductSelector = (state) => state.recommendProduct.recommendedProduct

export const categoryInfoSelector = (state) => state.categoryInfo.categoriesList

export const homeTabListSelector = (state) => state.homeTabList.homeTabList

export const voucherInfoSelector = (state) => state.voucherList.vouchersInfo

// Reviews Slice

export const SelectReviewSlice = (state) => state.reviews

export const reviewsSelector = createSelector(
  [SelectReviewSlice],
  (reviewsSlice) => reviewsSlice.reviews
)

export const reviewsCursorSelector = createSelector(
  [SelectReviewSlice],
  (reviewsSlice) => reviewsSlice.cursor
)

export const reviewsHasMoreSelector = createSelector(
  [SelectReviewSlice],
  (reviewsSlice) => reviewsSlice.hasMore
)

export const reviewsPreviousPagesSelector = createSelector(
  [SelectReviewSlice],
  (reviewsSlice) => reviewsSlice.previousPages
)

export const reviewsCurrentPageSelector = createSelector(
  [SelectReviewSlice],
  (reviewsSlice) => reviewsSlice.currentPage
)

export const reviewsIsLoadingSelector = createSelector(
  [SelectReviewSlice],
  (reviewsSlice) => reviewsSlice.isLoading
)