import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  recommendationsSelector,
  sellerInfoSelector,
} from "../store/selectors";
import { getRecommendationAsyncThunk } from "../store/recommendation/recommendationSlice";

const Recommendation = () => {
  const dispatch = useDispatch();
  const sellerInfo = useSelector(sellerInfoSelector);
  const recommendationData = useSelector(recommendationsSelector);

  const [nextCursor, setNextCursor] = useState(null);
  const [feedList, setFeedList] = useState([]);

  console.log("this is the recommendation data: ", recommendationData);
  console.log(
    "this is the Product_V2: ",
    recommendationData?.feed_list &&
      recommendationData?.feed_list[1]?.product_v2
      ? JSON.parse(recommendationData?.feed_list[1]?.product_v2)
      : ""
  );

  useEffect(() => {
    if (sellerInfo.sellerId) {
      dispatch(
        getRecommendationAsyncThunk({
          data: {
            seller_id: sellerInfo.sellerId,
            cursor: null, // Initial load should not use a cursor
          },
        })
      );
    }
  }, [dispatch, sellerInfo]);

  useEffect(() => {
    if (recommendationData?.next_cursor) {
      setNextCursor(recommendationData.next_cursor);
    }
    if (recommendationData?.feed_list) {
      setFeedList((prevFeedList) => [
        ...prevFeedList,
        ...recommendationData.feed_list,
      ]);
    }
  }, [recommendationData]);

  const handleLoadMore = () => {
    if (nextCursor) {
      dispatch(
        getRecommendationAsyncThunk({
          data: {
            seller_id: sellerInfo.sellerId,
            cursor: nextCursor,
          },
        })
      );
    }
  };

  return (
    <div className="recommendations h-full">
      {feedList.length > 0 ? (
        <>
          <div className="grid grid-cols-3 gap-5">
            {feedList.map((recommend, index) => {
              let product = JSON.parse(recommend?.product_v2)?.product_data;
              let image = product?.components[1001]?.tags[0]?.cover_tag?.image;
              let title = product?.components[1003]?.tags[0]?.title_tag;
              let promotion = product?.components[2008]?.tags[0]?.promotion_tag;

              return (
                <div className="product" key={product?.product_id ?? index}>
                  <div className="bg-[#ffffff14] text-white rounded-md p-5 w-full transition h-full">
                    <div className="flex items-start flex-col gap-4">
                      <div className="w-full h-[250px] rounded-[2px] flex items-center justify-center overflow-hidden relative">
                        <img
                          src={image.url_list[0]}
                          alt={`recommended-product${product?.product_id}`}
                          className="w-full h-[250px] rounded-[2px] object-cover"
                        />
                        <div className="text-[12px] font-bold absolute right-1 top-1 bg-[#000] text-white rounded p-2">
                          {promotion?.promotion_label?.text}
                        </div>
                      </div>
                      <div className="flex flex-col items-start">
                        <div className="text-[16px] font-bold text-start">
                          {title?.title}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {nextCursor && (
            <div className="flex items-center justify-center mt-4">
              <button
                onClick={handleLoadMore}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Load More
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center h-full">
          <h1 className="text-2xl font-medium text-white">
            No Recommendations Available for Your Country.
          </h1>
        </div>
      )}
    </div>
  );
};

export default Recommendation;
