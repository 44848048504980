import React, { useState, useEffect } from "react";
import Modelbox from "../Model/Modelbox";
import Reviewbox from "../Reviews/Reviewbox";
import { Link, Outlet, useLocation } from "react-router-dom";

const Playlistcards = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    // Set the active tab based on the current URL
    const currentPath = location.pathname;
    const activeNavItem = nav_list.find((nav) => nav.url === currentPath);
    if (activeNavItem) {
      setActiveTab(activeNavItem.name);
    }
  }, [location.pathname]);

  const nav_list = [
    { name: "Creator Info", url: "/creator-info" },
    { name: "Shop Info", url: "/shop-info" },
    { name: "Recommendation", url: "/recommendation" },
    { name: "Category Info", url: "/category-info" },
    { name: "Voucher Info", url: "/voucher-info" },
    { name: "Reviews", url: "/reviews" },
    { name: "Homepage Product", url: "/homepage-product" },
    { name: "Product List", url: "/product-list" },
    { name: "Recommended_Product", url: "/recommended-product" },
    { name: "Home_tab", url: "/home-tab" },
    { name: "Home Tab List", url: "/hometab-list" },
  ];

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <div className="Playlistcard_scroll">
      <div className="w-[95%] max-w-[1300px] mt-32 mb-3 m-auto">
        <div className="block bg-gray-800 rounded shadow-sm sm:pr-8 sm:pl-5 pl-2 pr-3">
          <div className="flex">
            <div className="py-10 text-base font-medium text-start text-gray-500 border-r border-gray-600 w-[230px]">
              <ul className="flex flex-col gap-5">
                {nav_list.map((nav) => (
                  <li className="mr-2" key={nav.name}>
                    <Link
                      to={nav.url}
                      onClick={() => handleTabClick(nav.name)}
                      className={`inline-block border-b border-transparent ${
                        activeTab === nav.name
                          ? "text-[#ffffff] font-medium bg-[#AF47D2] py-1 px-3 rounded-sm"
                          : "hover:border-[#AF47D2] hover:text-[#AF47D2]"
                      }`}
                    >
                      {nav.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="pl-8 py-10 grow">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playlistcards;
