import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeTabInfoSelector, sellerInfoSelector } from "../store/selectors";
import { getHometabInfoAsyncThunk } from "../store/homeTabInfo/homeTabInfoSlice";

const HomeTab = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const sellerInfo = useSelector(sellerInfoSelector);
  const HomeTabInfo = useSelector(HomeTabInfoSelector);
  console.log("🚀 ~ HomeTab ~ HomeTabInfo:", HomeTabInfo);

  useEffect(() => {
    if (sellerInfo?.sellerId) {
      dispatch(
        getHometabInfoAsyncThunk({
          data: {
            seller_id: sellerInfo.sellerId,
            sort_field: 1,
            sort_order: 1,
            page_size: 10,
            page_id: 1,
          },
        })
      ).then(() => setLoading(false));
    }
  }, [dispatch, sellerInfo]);

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <h1 className="text-2xl font-medium text-white">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="flex items-center justify-center h-full">
        {/* <h1 className="text-2xl font-medium text-white">
          This section is under construction. We are working on it.
        </h1> */}

        {HomeTabInfo && HomeTabInfo?.length > 0 ? (
          <div>
            {HomeTabInfo.map((product) => (
              <div key={product.id}>{product.name}</div>
            ))}
          </div>
        ) : (
          <h1 className="text-2xl font-medium text-white">There is no data.</h1>
        )}
      </div>
    </div>
  );
};

export default HomeTab;
