import { APIurls } from "../constants.js";
// import apiService from ".";
// import multipart from "./multipart.js";
import axios from 'axios'

export const ApiRequests = {

    getSellerInfo: async ({ uniqueId }) => await axios.get(`${APIurls.sellerInfo}${uniqueId}`),

    getCreatorInfo: async (id) => await axios.get(`${APIurls.creatorInfo}${id}`),

    getHomePageProductInfo: async (data) => await axios.post(APIurls.homePageProductInfo, data),

    getRecommendation: async (data) => { console.log("data is:", data); return await axios.post(APIurls.recommendation, data) },

    getProductList: async (data) => await axios.post(APIurls.productList, data),

    getRecommendedProduct: async (data) => await axios.post(APIurls.recommendedProduct, data),

    getHomeTabInfo: async (data) => await axios.post(APIurls.homeTabInfo, data),

    getHomeTabList: async (data) => await axios.post(APIurls.homeTabList, data),

    getCategoryInfo: async (data) => await axios.post(APIurls.categoryInfo, data),

    getVoucherList: async (data) => await axios.post(APIurls.voucherList, data),

    getReviews: async (data) => { console.log(data); return await axios.post(APIurls.reviews, data) },

    getShopInfo: async (data) => await axios.post(APIurls.shopInfo, data)

};
