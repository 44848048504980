import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { getContentsAsyncThunk } from "./contentSlice";


export const getCategoryInfoAsyncThunk = createAsyncThunk(
  "categoryInfo/getCategoryInfoAsyncThunk",
  catchAsync(async ({data}, {getState}) => {
    const state = getState();
    const response = await ApiRequests.getCategoryInfo({
      ...data,
      country_code: state.sellerInfo.sellerInfo.countryCode,
      scroll_param: "0",
      page_size: "20",
      query: ""
    });
    console.log("The get Response is:", response?.data)
    return response?.data;
  })
);







///////////////////////////////////////////////////

const initialState = {
  //news states
  categoriesList: null,
  // query: "",
  // pageSize: "20",
  // scrollParam: "",

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  // search: null,
  // categoryId: null,
  // categories: [],
  // order: "asce",
};

const categoryInfoSlice = createSlice({
  name: "categoryInfo",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(getCategoryInfoAsyncThunk.fulfilled, (state, action) => {
          if (action.payload) {
            state.categoriesList = action.payload.data.category_list
          } else {
            state.categoryInfo = null
          }
      })
      
     
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getCategoryInfoAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  categoryInfoSlice.actions;

export default categoryInfoSlice.reducer;
