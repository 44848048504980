const localhost = process.env.REACT_APP_NODE_ENV === "localhost";
const development = process.env.REACT_APP_NODE_ENV === "development";
export const app_mode = localhost
  ? "localhost"
  : development
    ? "development"
    : "production";

export const contentPath =
  process.env.REACT_APP_BasePath ??
  (localhost
    // ? "http://localhost:5001/"
    ? "https://tokshopapi.toktools.online/"
    : development
      // ? "http://localhost:5001/"
      ? "https://tokshopapi.toktools.online/"
      : "https://tokshopapi.toktools.online/"
  );


export const APIurls = {
  sellerInfo: 'https://bogustokscrpapi.toktools.online/v1/tiktok/userinfo?username=',
  creatorInfo: contentPath + "creator/",
  shopInfo: contentPath + "shop_info",
  recommendation: contentPath + "recommendation",
  categoryInfo: contentPath + "category_info",
  voucherList: contentPath + "voucher_list",
  reviews: contentPath + "review",
  homePageProductInfo: contentPath + "homepage_product_info",
  productList: contentPath + "product_list",
  recommendedProduct: contentPath + "recommend_product",
  homeTabInfo: contentPath + "home_tab_info",
  homeTabList: contentPath + "home_tab_list",

};

export const countryCodes = {
  Indonesia: 'ID',
  Malaysia: 'MY',
  Philippines: 'PH',
  Singapore: 'SG',
  Thailand: 'TH',
  'UK': 'GB',
  'USA': 'US',
  Vietnam: 'VN'
};