import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { getContentsAsyncThunk } from "./contentSlice";


export const getReviewsAsyncThunk = createAsyncThunk(
  "reviews/getReviewsAsyncThunk",
  catchAsync(async ({data}, {getState}) => {
    const state = getState();
    const response = await ApiRequests.getReviews({
      ...data,
      country_code: state.sellerInfo.sellerInfo.countryCode,
      cursor: 0,
      size: "10",
      query: state.reviews.query,
    });
    console.log("The get Response is:", response?.data)
    return response?.data;
  })
);

export const getNextReviewsAsyncThunk = createAsyncThunk(
  "reviews/getNextReviewsAsyncThunk",
  catchAsync(async ({data}, {getState}) => {
    const state = getState();
    console.log("clicking the next Page:", state.reviews.previousPages)
    const response = await ApiRequests.getReviews({
      ...data,
      country_code: state.sellerInfo.sellerInfo.countryCode,
      cursor: state.reviews.cursor,
      size: "10",
      query: state.reviews.query,
    });
    console.log("The get Response is:", response?.data)
    return response?.data;
  })
);

export const getPreviousReviewsAsyncThunk = createAsyncThunk(
  "reviews/getPreviousReviewsAsyncThunk",
  catchAsync(async ({data}, {getState}) => {
    const state = getState();
    console.log("clicking the previous Page:", state.reviews.previousPages)
    const response = await ApiRequests.getReviews({
      ...data,
      country_code: state.sellerInfo.sellerInfo.countryCode,
      cursor: state.reviews.previousPages[state.reviews.previousPages.length - 2],
      size: "10",
      query: state.reviews.query,
    });
    console.log("The get Response is:", response?.data)
    return response?.data;
  })
);







///////////////////////////////////////////////////

const initialState = {
  //news states
  reviews: null,
  cursor: 0,
  query: "",
  hasMore: false,
  page: 0,
  previousPages: [0],
  currentPage: 1,
  isLoading: null,

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  // search: null,
  // categoryId: null,
  // categories: [],
  // order: "asce",
};

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(getReviewsAsyncThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getReviewsAsyncThunk.rejected, (state, action) => {
        state.isLoading = null;
      })

      .addCase(getReviewsAsyncThunk.fulfilled, (state, action) => {
          if (action.payload) {
            state.reviews = action.payload.data
            state.hasMore = action.payload.data.has_more;
            // console.log("The Previous pages before get.fulfilled", state.previousPages)
            // state.previousPages = state.previousPages.includes(state.cursor) ? state.previousPages : [...state.previousPages, state.cursor];
            // console.log("The Previous pages on get.fulfilled", state.previousPages)
            state.cursor = action.payload.data.next_cursor;
            state.previousPages = [0];
            state.currentPage = 1;
            state.isLoading = null;
          } else {
            state.reviews = null
          }
      })



      .addCase(getNextReviewsAsyncThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getNextReviewsAsyncThunk.rejected, (state, action) => {
        state.isLoading = null;
      })

      .addCase(getNextReviewsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.reviews = action.payload.data
          state.hasMore = action.payload.data.has_more;
          // state.previousPages = state.previousPages.includes(state.cursor) ? state.previousPages : [...state.previousPages, state.cursor];
          state.previousPages = [...state.previousPages, state.cursor];
          console.log("The Previous pages on next.fulfilled", state.previousPages)
          state.cursor = action.payload.data.next_cursor;
          state.currentPage = state.currentPage + 1;
          state.isLoading = null;
        } else {
          state.reviews = null
        }
      })



      
      .addCase(getPreviousReviewsAsyncThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getPreviousReviewsAsyncThunk.rejected, (state, action) => {
        state.isLoading = null;
      })

      .addCase(getPreviousReviewsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.reviews = action.payload.data
          state.hasMore = action.payload.data.has_more;
          const removed_cursor = state.previousPages.pop();
          console.log(removed_cursor) // Remove the last element
          state.previousPages = [...state.previousPages]; // Assign the updated array
          console.log("The Previous pages on previous.fulfilled", [...state.previousPages])
          state.cursor = action.payload.data.next_cursor;
          state.currentPage = state.currentPage - 1;
          state.isLoading = null;
        } else {
          state.reviews = null
        }
    })
      
     
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getReviewsAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  reviewsSlice.actions;

export default reviewsSlice.reducer;
