import React, { useEffect } from "react";
import "./App.scss";
import Footer from "./Components/Footer";

import { Route, Routes } from 'react-router-dom';
import Home from "./Pages/Home";
import Topbar from "./Components/Topbar";
import CreatorInfo from "./Pages/CreatorInfo";
// import Playlistcards from "./Components/Cards/Playlistcards";
import ShopInfo from './Pages/ShopInfo'
import HomepageProduct from "./Pages/HomepageProduct";
import ProductList from "./Pages/ProductList";
import RecommendedProduct from "./Pages/RecommendedProduct";
import Recommendation from "./Pages/Recommendation";
import HomeTab from "./Pages/HomeTab";
import HomeTabList from "./Pages/HomeTabList";
import CategoryInfo from "./Pages/CategoryInfo";
import Reviews from "./Pages/Reviews";
import VoucherInfo from "./Pages/VoucherInfo";



function App() {

  

  return (
    // <div className="App">
      <Routes>
        <Route path="/" element={ <Home/> }>
          <Route path="creator-info" element={ <CreatorInfo/> }/>
          <Route path="shop-info" element={ <ShopInfo/> }/>
          <Route path="homepage-product" element={ <HomepageProduct/> }/>
          <Route path="product-list" element={ <ProductList/> }/>
          <Route path="recommended-product" element={ <RecommendedProduct/> }/>
          <Route path="recommendation" element={ <Recommendation/> }/>
          <Route path="home-tab" element={ <HomeTab/> }/>
          <Route path="category-info" element={ <CategoryInfo/> }/>
          <Route path="hometab-list" element={ <HomeTabList/> }/>
          <Route path="voucher-info" element={ <VoucherInfo/> }/>
          <Route path="reviews" element={ <Reviews/> }/>
        </Route>
      </Routes>
      // <Playlistcards/> 
    // </div>

  );
}

export default App;
