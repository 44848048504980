import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { getContentsAsyncThunk } from "./contentSlice";


export const getRecommendedProductAsyncThunk = createAsyncThunk(
  "recommendedProduct/getRecommendedProductAsyncThunk",
  catchAsync(async ({ data }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.getRecommendedProduct({
      ...data,
      country_code: state.sellerInfo.sellerInfo.countryCode,
    });
    console.log("The get Response is:", response?.data)
    return response?.data;
  })
);




///////////////////////////////////////////////////

const initialState = {
  //news states
  recommendedProduct: null,

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  // search: null,
  // categoryId: null,
  // categories: [],
  // order: "asce",
};

const recommendedProductSlice = createSlice({
  name: "recommendedProduct",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder

      .addCase(getRecommendedProductAsyncThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.recommendedProduct = action.payload.data
        } else {
          state.recommendedProduct = null
        }
      })


      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getRecommendedProductAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  recommendedProductSlice.actions;

export default recommendedProductSlice.reducer;
