import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { categoryInfoSelector, sellerInfoSelector } from "../store/selectors";
import { getCategoryInfoAsyncThunk } from "../store/categoryInfo/categoryInfoSlice";

const CategoryInfo = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const sellerInfo = useSelector(sellerInfoSelector);
  const categoriesList = useSelector(categoryInfoSelector);

  console.log("categoryInfo is", categoriesList);

  useEffect(() => {
    dispatch(
      getCategoryInfoAsyncThunk({
        data: {
          seller_id: sellerInfo.sellerId,
        },
      })
    ).then(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <h1 className="text-2xl font-medium text-white">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="category-info">
      <div className="grid grid-cols-3 gap-5">
        {categoriesList &&
          categoriesList.map((category) => (
            <div key={category.category_id}>
              <div className="bg-[#ffffff14] text-white rounded-md p-5 w-full transition">
                <div className="flex items-start flex-col gap-4">
                  <div className="w-full h-[250px] rounded-[2px] flex items-center justify-center	overflow-hidden">
                    <img
                      src={category.category_pic}
                      alt=""
                      className="w-full h-[250px] rounded-[2px] object-cover"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <div className="text-[22px] font-bold text-start">
                      {category.category_english_name}{" "}
                    </div>

                    <div className="text-[14px] opacity-90 text-start">
                      Variety: {category.product_count}
                    </div>
                  </div>
                </div>
              </div>
              {/* <img src={category.category_pic} alt="it's a category" height='100px'/>
              <p className='text-black'></p>
              <p className='text-black'></p> */}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CategoryInfo;
