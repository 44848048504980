import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { rootReducer } from './root-reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    "sellerInfo"
  ], // specify which reducers you want to persist
};

// Create a persisted reducer
// const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWares = [
  process.env.NODE_ENV !== "production" && logger,
].filter(Boolean);

const store = configureStore({
  // reducer: persistedReducer,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleWares),
});

// const persistor = persistStore(store);

// export { store, persistor };
export { store };

