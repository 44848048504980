import { useDispatch, useSelector } from "react-redux";
import { homeTabListSelector, sellerInfoSelector } from "../store/selectors";
import { useEffect, useState } from "react";
import { getHomeTabListAsyncThunk } from "../store/homeTabList/homeTabListSlice";
import "../hometablist.css";

const HomeTabList = () => {
  const dispatch = useDispatch();
  const sellerInfo = useSelector(sellerInfoSelector);
  const homeTabList = useSelector(homeTabListSelector);

  const [activeTab, setActiveTab] = useState(homeTabList?.[0]?.tab_type || 1);

  useEffect(() => {
    if (sellerInfo.sellerId) {
      dispatch(
        getHomeTabListAsyncThunk({
          data: {
            seller_id: sellerInfo.sellerId,
          },
        })
      );
    }
  }, [sellerInfo, dispatch]);

  const handleTabClick = (tabType) => {
    setActiveTab(tabType);
  };

  const getTabContent = () => {
    const tab = homeTabList?.find((tab) => tab.tab_type === activeTab);
    return tab ? (
      <div className="tab-content">{tab.tab_name} Content</div>
    ) : null;
  };

  return (
    <div className="home-tab-list-container">
      <div className="tabs-container">
        <div className="tabs">
          {homeTabList?.map((tab) => (
            <div
              key={tab.tab_type}
              className={`tab-item ${
                tab.tab_type === activeTab ? "active" : ""
              }`}
              onClick={() => handleTabClick(tab.tab_type)}
            >
              {tab.tab_name}
            </div>
          ))}
        </div>
        <div className="tab-content-container">{getTabContent()}</div>
      </div>
    </div>
  );
};

export default HomeTabList;
