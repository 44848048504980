import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
import { setSellerId, setSellerReviews } from "../sellerInfo/sellerInfoSlice";


export const getCreatorInfoAsyncThunk = createAsyncThunk(
  "creator/getCreatorInfoAsyncThunk",
  catchAsync(async (id, { dispatch }) => {
    const response = await ApiRequests.getCreatorInfo(id);
    console.log("The get Response is:", response?.data)
    if (response.data.data?.shop?.seller_id)
      dispatch(setSellerId(response.data.data.shop.seller_id))

    if (response.data?.data?.shop?.display_review_count)
      dispatch(setSellerReviews(response.data.data.shop.display_review_count))
    return response?.data;
  })
);


///////////////////////////////////////////////////

const initialState = {
  //news states
  creatorInfo: null,

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  // search: null,
  // categoryId: null,
  // categories: [],
  // order: "asce",
};

const creatorSlice = createSlice({
  name: "performer",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder

      .addCase(getCreatorInfoAsyncThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.creatorInfo = action.payload.data
        } else {
          state.creatorInfo = null
        }
      })


      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getCreatorInfoAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  creatorSlice.actions;

export default creatorSlice.reducer;
